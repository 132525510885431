import * as React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import HomeAlt from './pages/HomeAlt';
import HomePills from './pages/HomePills';
import TrendsPage from './pages/TrendsPage';
import StatsPage from './pages/StatsPage';
import PhotoPage from './pages/PhotoPage';
import Signin from './pages/Signin';
import Photos from './components/Photos';
import YearPage from './pages/YearPage';
import SettingsPage from './pages/SettingsPage';
import DetailsPage from './pages/DetailsPage';
import { MyProvider } from './Context';
import { ProtectedRoute } from './ProtectedRoute';


import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MainNavbar from './components/MainNavbar';

import {
  Link as RouterLink,
} from 'react-router-dom';

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import PhotoIcon from '@mui/icons-material/Photo';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import './App.css';

function App() {
  
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
   const [value, setValue] = React.useState(0);
      
   const theme = React.useMemo(
     () =>
       createTheme({
         palette: {
           mode: prefersDarkMode ? 'dark' : 'light',
         },
       }),
     [prefersDarkMode],
   ); 
  
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyProvider>
        <MainNavbar/>
          <Routes>
            <Route path="/" element={<ProtectedRoute><HomePills /></ProtectedRoute>} />
            <Route path="/HomeAlt" element={<ProtectedRoute><HomeAlt /></ProtectedRoute>} />
            <Route path="/HomePills" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/:date_key" element={<ProtectedRoute><HomePills /></ProtectedRoute>} />
            <Route path="/Trends" element={<ProtectedRoute><TrendsPage /></ProtectedRoute>} />
            <Route path="/Stats" element={<ProtectedRoute><StatsPage /></ProtectedRoute>} />
            <Route path="/Stats/:group/:id" element={<ProtectedRoute><StatsPage /></ProtectedRoute>} />
            <Route path="/Photos" element={<ProtectedRoute><PhotoPage /></ProtectedRoute>} />
            <Route path="/Photos/:group" element={<ProtectedRoute><Photos /></ProtectedRoute>} />
            <Route path="/Year" element={<ProtectedRoute><YearPage /></ProtectedRoute>} />
            <Route path="/Details/:group/:id" element={<ProtectedRoute><DetailsPage /></ProtectedRoute>} />
            <Route path="/Settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
            <Route path="/Signin" element={<Signin />} />
          </Routes>
          
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className="bottom_nav">
            <BottomNavigation
              showLabels              
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction component={RouterLink} to="/" label="Home" icon={<HomeIcon />} />
              <BottomNavigationAction component={RouterLink} to="/HomeAlt" label="HomeAlt" icon={<HomeIcon />} />
              <BottomNavigationAction component={RouterLink} to="/HomePills" label="HomePills" icon={<HomeIcon />} />
              <BottomNavigationAction component={RouterLink} to="/Trends" label="Trends" icon={<TrendingUpIcon />} />
              <BottomNavigationAction component={RouterLink} to="/Stats" label="Stats" icon={<BarChartIcon />} />
              <BottomNavigationAction component={RouterLink} to="/Photos" label="Photos" icon={<PhotoIcon />} />
              <BottomNavigationAction component={RouterLink} to="/Year" label="Analyze" icon={<CalendarMonthIcon />} />
            </BottomNavigation>
          
          </Paper>         
          
        </MyProvider>
      </ThemeProvider> 
    </Router>   
    
  );
}

export default App;
