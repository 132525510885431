import * as Model from '../model';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

function YearPage() {
  
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  
  const handleModelResults = (result) => {
    console.log(result);
    if (result.success) {
      setResponse(result.data.analysis);
    } else {
      setError(result.error);
    }
    setIsLoading(false);
  };

  const handleReload = async () => {
    console.log('handleReload');
    setIsLoading(true);
    setError('');
    Model.prep_model(handleModelResults);
    
  };

  const handleSendResults = (result) => {
    console.log(result);
    if (result.success) {
      setResponse(result.response);
    } else {
      setError(result.error);
    }
    setIsLoading(false);
  };

  const handleSend = async () => {
    setIsLoading(true);
    setError('');
    Model.ask_model(message, handleSendResults);
  };

  return ( 
    <Container>
      <Stack gap={2}>
        <Stack spacing={2}>
          <Button 
            variant="contained" 
            onClick={handleReload}
            disabled={isLoading}
          >
            Reload
          </Button>

          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              label="Enter your message"
              disabled={isLoading}
            />
            <Button
              variant="contained"
              onClick={handleSend}
              disabled={isLoading || !message.trim()}
            >
              Send
            </Button>
          </Stack>

          {response && (
            <Paper sx={{ p: 2 }}>
              <Typography>
                {response}
              </Typography>
            </Paper>
          )}

          {isLoading && (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Paper sx={{ p: 2, bgcolor: '#fff3f3' }}>
              <Typography color="error">
                {error}
              </Typography>
            </Paper>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}

export default YearPage;
