import axios from 'axios';

export function authenticate_user(login, password, call_back) {
	const fetchData = async () => {
  		try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'authenticate_user', {login, password});
			call_back(result.data)
  		} catch (error) {
	  		console.error(error);
  		}
	};
	fetchData()
}

// http://192.168.7.37:3001/track//track/get_image/thumb/12
// http://192.168.7.37:3001/track/get_image/image/1
export function image(type, id){
	return process.env.REACT_APP_WS_DIRECT_URL + 'get_image/'+type+'/'+id
}

export function nice_date(dateStr){
    // Create a Date object from the input string
    const date = new Date(dateStr);

    // Extract the month, day, and year
    let month = date.getMonth() + 1;  // getMonth() returns 0-11
    let day = date.getDate();
    let year = date.getFullYear() % 100; // Get the last two digits of the year

    // Return the formatted string
    return `${month}/${day}/${year}`;
}

export function get_details(group, id, setItems) {
	const fetchData = async () => {
  		try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'get_details', {group, id});
			setItems(result.data.data);
  		} catch (error) {
	  		console.error(error);
  		}
	};
	fetchData()
}

export function load_home(date_key, setItems, setState, call_back) {
	const fetchData = async () => {
  		try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'load_home', {date_key});
			setItems(result.data.data.tags);
			setState(result.data.data.state);
			call_back(result.data.data.date, result.data.data.date_key)
  		} catch (error) {
	  		console.error(error);
  		}
	};
	fetchData()
}

export function load_chart(setItems, items, initalLoadCallback) {
	const fetchData = async () => {
  		try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'load_chart', {chips_selected:items.chips_selected, grouping:items.grouping, scope:items.scope, sortAsc:items.sortAsc});
			setItems(result.data);
			if(initalLoadCallback){ initalLoadCallback(); }
  		} catch (error) {
	  		console.error(error);
  		}
	};
	fetchData()
}

export function get_measurements(pinned, setItems, setOther) {
	const fetchData = async () => {
		  try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'load_measurements', {pinned:pinned});
			//console.log(result.data.data);
			setItems(result.data.data);
			setOther(result.data.other);
		  } catch (error) {
			  console.error(error);
		  }
	};
	fetchData()
}

export function get_goals(date_key, setItems, call_back) {
	const fetchData = async () => {
		  try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'goals', {date_key});
			setItems(result.data.data);
			call_back(result.data.data);
		  } catch (error) {
			  console.error(error);
		  }
	};
	fetchData()
}

export function get_weekheader(date_key, setItems) {
	const fetchData = async () => {
		  try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'weekheader', {date_key});			
			setItems(result.data.data);
		  } catch (error) {
			  console.error(error);
		  }
	};
	fetchData()
}


export function get_trends(setItems) {
	
	const fetchData = async () => {
		  try {
			const result = await axios.get(process.env.REACT_APP_WS_BASE_URL + 'get_trends');
			setItems(result.data.data);
		  } catch (error) {
			  console.error(error);
		  }
	};
	fetchData()
}

export function get_photos(limit, group, setItems) {
	
	const fetchData = async () => {
		  try {
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'get_photos', {limit:limit, group:group});
			setItems(result.data.data);
		  } catch (error) {
			  console.error(error);
		  }
	};
	fetchData()
}

export function click_tag(id, date, count, sub_id, call_back) {
	
	const fetchData = async () => {
		  try {
			//console.log('click_tag: ' + id, ' new: ', new_value)
			const result = await axios.post(process.env.REACT_APP_WS_BASE_URL + 'set_tag', { id, date, count, sub_id });            
			//console.log(result)
			//setItems(result.data.data);
			call_back && call_back(result.data.data)
		  } catch (error) {
			  console.error(error);
		  }
	};
	fetchData()	
	
}

export function ask_model(question, call_back) {
	
	const fetchData = async () => {
		  try {
			const result = await axios.get(process.env.REACT_APP_WS_BASE_URL + 'ask_model', {question:question});
			call_back(result.data);
		  } catch (error) {
			console.error(error);
		  }
	};
	fetchData()
}

export function prep_model(setItems) {
	
	const fetchData = async () => {
		  try {
			const result = await axios.get(process.env.REACT_APP_WS_BASE_URL + 'prep_model');
			setItems(result.data);
		  } catch (error) {
			console.error(error);
		  }
	};
	fetchData()
}